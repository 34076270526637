<template lang="pug">
  v-card
      v-card-title
        h2.card-title {{ 'modals.planning.create.title' | translate }}

      .tabs
        v-btn(
          :class="{active: item.silages_list.length}") {{ "actions.planning_with_silos" | translate }}

        v-btn(
          :class="{active: !item.silages_list.length}") {{ "actions.planning_without_silos" | translate }}

      edit-default-modal(
        v-if="item.silages_list.length"
        :item="item"
        @close="close"
      )

      edit-period-modal(
        v-if="!item.silages_list.length"
        :item="item"
        @close="close"
      )

</template>

<script>
import EditDefaultModal from './EditDefaultModal.vue'
import EditPeriodModal from './EditPeriodModal.vue'

export default {
  name: 'EditPlanningModal',

  components: {
    EditDefaultModal,
    EditPeriodModal
  },

  props: {
    item: Object
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  width: 100%;
  margin-top: 10px;
  background: #F8F8F8;
  display: flex;
  justify-content: space-around;
  padding:0;

  .v-btn--default {
    background: none;
    color: #979797;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    border-radius: 0;
    border-bottom: 3px solid #F8F8F8;
    cursor: default;

    &.active {
      color: #4EAC55;
      border-color: #4EAC55;
    }
  }
}
</style>
