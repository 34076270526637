<template lang="pug">
  v-card-content
    v-date-field(
      :date-from="filterMinDate"
      :date-to="filterMaxDate"
      :label="$t('base.date')"
      v-model="form.planned_at")

    v-select.mb-3(
      :label="$t('base.ration')"
      :options="rationsList"
      v-model="form.ration_id")

    v-select.mb-3(
      :label="$t('base.recipe')"
      :options="recipes"
      v-model="form.recipe_id")

    v-switch.mr-1(
        :label="$t('modals.planning.create.require_qr_code')"
        v-model="form.require_qr_code")

    v-switch.mr-1(
        :label="$t('modals.planning.create.require_queue')"
        v-model="form.require_queue")

    v-text-field.mb-2(
      :label="$t('base.weight')"
      v-model="form.planned_weight")

    v-text-field.mb-2(
      :label="$t('pages.settings.mixing_time')"
      v-model="form.mixing_time")

    v-card-actions
      v-btn(
        :disabled="!isRationValid || !isDateSelected || !isRecipeSelected"
        :loading="loading"
        @click="onUpdate").mr-2 {{ 'actions.save' | translate }}
      delete-btn(
        ref="deleteBtn"
        :loading="deleting"
        @delete="onDelete")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import DeleteBtn from '@/components/common/delete-btn/DeleteBtn'

export default {
  name: 'EditPeriodModal',

  components: {
    DeleteBtn
  },

  props: {
    item: Object
  },

  data: () => ({
    form: {
      ration_id: null,
      recipe_id: null,
      planned_at: null,
      require_qr_code: false,
      require_queue: false,
      mixing_time: 0,
      planned_weight: 0,
      is_started_anyway: false
    },
    loading: false,
    deleting: false
  }),

  computed: {
    ...mapGetters([
      'rationsList',
      'recipesList'
    ]),

    isRationValid () {
      return !!this.form.ration_id
    },

    isDateSelected () {
      return this.form.planned_at !== null
    },

    isRecipeSelected () {
      return this.form.recipe_id !== null
    },

    recipes () {
      return this.recipesList.filter(item => item.ration_id === this.form.ration_id)
    },

    filterMinDate () {
      return new Date()
    },

    filterMaxDate () {
      return new Date(new Date().setDate(new Date().getDate() + 7))
    }
  },

  async mounted () {
    await this.fetchRationsList()
    await this.fetchRecipesList({ status: true })
    this.form.planned_at = this.item.date
    this.form.ration_id = this.item.ration.id
    this.form.recipe_id = this.item.recipe.id
    this.form.require_qr_code = this.item.require_qr_code
    this.form.require_queue = this.item.require_queue
    this.form.mixing_time = this.item.mixing_time
    this.form.planned_weight = this.item.plan_weight
  },

  methods: {
    ...mapActions([
      'fetchRecipesList',
      'fetchRationsList',
      'updateTimetable',
      'deleteTimetable'
    ]),

    async onUpdate () {
      this.loading = true
      const { errors } = await this.$store.dispatch('updateTimetableSchedule', {
        id: this.item.timetable_id,
        data: this.form
      })
      this.loading = false
      if (!errors) {
        this.$emit('close')
      } else {
        for (const key in errors) {
          for (const msg of errors[key]) {
            this.$toasted.show(msg, {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      }
    },

    async onDelete () {
      this.deleting = true
      const { success } = await this.$store.dispatch('deleteTimetable', this.item)
      this.deleting = false
      if (success) {
        this.$refs.deleteBtn.close()
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cols {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .col {
    &:first-child {
      width: calc(70% - 10px);
    }

    &:last-child {
      display: flex;
      align-items: center;
      width: calc(30% - 10px);
    }
  }
}
.v-switch__wrapper {
  margin: 10px 0;
}
</style>
